import React from 'react'
import SEO from '~/components/seo'
import { BrowserView, MobileView } from 'react-device-detect'

const PressPage = () => (
  <>
    <SEO
      title="BDST | BY BEN TAVERNITI | Bureau de Stil"
      keywords={[
        `bureau de Stil`,
        `ben tavertini`,
        `kim kardashian`,
        'LA Fashion',
        'fashion',
        'joyce bonelli',
        'unravel project',
        'trend brand',
        'online shop',
      ]}
    />
    <BrowserView>press page</BrowserView>
    <MobileView>press page</MobileView>
  </>
)

export default PressPage
